import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "socials-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialIcon = _resolveComponent("SocialIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SocialIcon, {
      url: "https://www.linkedin.com/in/amanda-warchal/",
      icon: require('@/assets/icons/linkedIcon.png')
    }, null, 8, ["icon"]),
    _createVNode(_component_SocialIcon, {
      url: "mailto:warchalamanda@gmail.com",
      icon: require('@/assets/icons/emailIcon.png')
    }, null, 8, ["icon"]),
    _createVNode(_component_SocialIcon, {
      url: "https://amandawarchal.me/files/AmandaWarchalResume.pdf",
      icon: require('@/assets/icons/resumeIcon.png')
    }, null, 8, ["icon"])
  ]))
}