import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SocialsButtons = _resolveComponent("SocialsButtons")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _cache[2] || (_cache[2] = _createElementVNode("aside", null, [
        _createElementVNode("h1", null, "Amanda Warchal"),
        _createElementVNode("br"),
        _createElementVNode("h2", null, "Biologist - Artist")
      ], -1)),
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Work")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/about" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("About")
          ])),
          _: 1
        }),
        _createVNode(_component_SocialsButtons)
      ])
    ]),
    _createElementVNode("main", null, [
      _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
      _createVNode(_component_router_view)
    ]),
    _createElementVNode("footer", null, [
      _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
      _createElementVNode("p", null, "© " + _toDisplayString($options.getYear()) + " Amanda Warchal", 1),
      _createVNode(_component_SocialsButtons)
    ])
  ]))
}